import { ColorTranslator } from "colortranslator";

export const hslToHex = (hsl: string) => {
  const colorHsl = new ColorTranslator(hsl);
  return colorHsl.HEX;
};

export const PASTELS = ["#C5D9AB", "#FBDEA3", "#8ECCD4", "#A178B8", "#E39AC7"];
export const PASTELS2 = [
  "#DEFDE0",
  "#DEF3FD",
  "#FCF7DE",
  "#F0DEFD",
  "#FDDFDF",
  "#DCEFFC",
  "#F2DBE0",
];
