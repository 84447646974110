import { API_V2 } from "@/config/api";
import { Users } from "@/types/models/Users";
import apiRequest from "./api";

export const routes = {
  me: `${API_V2}/users/me`,
  list: `${API_V2}/users`,
  create: `${API_V2}/users`,
  update: (id: number) => `${API_V2}/users/${id}`,
  delete: (id: number) => `${API_V2}/users/${id}`,
};

export interface DonorsRequest {
  page: number;
  limit: number;
  sortBy: "createdAt" | "lastName";
  sortDirection: "ASC" | "DESC";
}

export interface UsersResponse {
  result: Users[];
  total: number;
  page: number;
  pages: number;
}

interface CreateUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  role: Users["role"];
}

interface UpdateUserRequest {
  firstName: string;
  lastName: string;
  role: Users["role"];
}

export const createUser = (input: CreateUserRequest) =>
  apiRequest<Users>(routes.create, {
    method: "POST",
    data: input,
  });

export const updateUser = (userId: number, input: UpdateUserRequest) =>
  apiRequest<Users>(routes.update(userId), {
    method: "POST",
    data: input,
  });

export const deleteUser = (userId: number) =>
  apiRequest<Users>(routes.delete(userId), {
    method: "DELETE",
  });
