import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { authorize, signIn, signOut } from "@/services/auth.service";
import { clearAuthTokens, isLoggedIn, setAuthTokens } from "axios-jwt";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { errorToast, infoToast } from "@/lib/toast";
import { useMe } from "@/hooks/useMe";
import { Users } from "@/types/models/Users";

export interface ContextAuth {
  authenticated: boolean;
  loginRequest: (email: string) => Promise<void>;
  authorizeRequest: (code: string, email?: string) => Promise<void>;
  logout: () => Promise<void>;
  user: Users | undefined;
}
const AuthContext = createContext<ContextAuth | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const loginRef = useRef(false);

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>();

  const { user } = useMe(authenticated);

  const loginRequest = useCallback(
    async (email: string) => {
      try {
        await signIn({ email });
        setEmail(email);
        router.push(`/signin/verify?email=${email}`);
      } catch (e) {
        console.error(e);
      }
    },
    [router]
  );

  const checkAuth = useCallback(
    async (noRedirect?: boolean) => {
      const loggedIn = await isLoggedIn();
      setAuthenticated(loggedIn);
      if (
        !loggedIn &&
        !pathname.includes("/signin") &&
        !pathname.includes("/signup")
      ) {
        infoToast("Your session has ended, please sign in again.");
        router.push("/signin");
      }

      if (pathname.includes("verify") && !email && !noRedirect) {
        infoToast("Redirecting to signin");
        router.push("/signin");
      }
    },
    [email, pathname, router]
  );

  const authorizeRequest = useCallback(
    async (code: string, inputEmail?: string) => {
      try {
        const userEmail = inputEmail ?? email;
        if (!userEmail) {
          throw new Error("No email provided");
        }
        const response = await authorize({
          mode: "otp",
          input: { email: userEmail, otp: code },
        });
        setAuthTokens({
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
        });
        await checkAuth(true);
        router.push("/");
        infoToast(`Welcome back ${response.firstName}!`);
      } catch (e) {
        console.error(e);
      }
    },
    [checkAuth, email, router]
  );

  useEffect(() => {
    const searchEmail = searchParams.get("email");
    const code = searchParams.get("code");
    if (!email && searchEmail) {
      setEmail(searchEmail);
    }
    if (searchEmail && code && !loginRef.current) {
      loginRef.current = true;
      authorizeRequest(code, searchEmail);
    }
  }, [authorizeRequest, email, searchParams]);

  const logout = useCallback(async () => {
    try {
      await signOut();
    } catch (e) {
      console.error(e);
    }
    clearAuthTokens();
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    checkAuth();
    const intervalId = setInterval(checkAuth, 5000);
    return () => clearInterval(intervalId);
  }, [checkAuth]);

  const context = useMemo(
    () => ({
      authenticated,
      loginRequest,
      authorizeRequest,
      logout,
      user,
    }),
    [authenticated, authorizeRequest, loginRequest, logout, user]
  );
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

function useAuth(): ContextAuth {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
