import { Users } from "@/types/models/Users";
import { API_V2 } from "@/config/api";
import apiRequest from "./api";

export const routes = {
  signin: `${API_V2}/auth/login/request`,
  signOut: `${API_V2}/auth/logout`,
  authorize: `${API_V2}/auth/login/validate`,
  token: `${API_V2}/auth/refresh`,
};

interface RequestNewTokenRequest {
  token: string;
}

interface Token {
  token: string;
  expiresAt: number;
}

interface RequestNewTokenResponse {
  accessToken: Token;
  refreshToken: Token;
}

export const requestNewToken = ({ token }: RequestNewTokenRequest) =>
  apiRequest<RequestNewTokenResponse>(routes.token, {
    method: "POST",
    data: {},
  });

export interface SignInRequest {
  email: string;
}
interface SignInResponse {}

export const signIn = ({ email }: SignInRequest) =>
  apiRequest<SignInResponse>(routes.signin, {
    method: "POST",
    data: { email, type: "charity" },
  });

export const signOut = () =>
  apiRequest<SignInResponse>(routes.signOut, {
    method: "POST",
    data: {},
  });

export interface AuthorizeRequest {
  mode: "otp";
  input: {
    email: string;
    otp: string;
  };
}

export const authorize = (input: AuthorizeRequest) =>
  apiRequest<Users>(routes.authorize, {
    method: "POST",
    data: { ...input, type: "charity" },
  });

interface Token {
  token: string;
}
