import * as allColors from "@radix-ui/colors";

const defaultTheme = {
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: [
    "1",
    "1.2",
    "1.3",
    "1.4",
    "1.5",
    "16px",
    "18px",
    "24px",
    "28px",
  ],
  fontSizes: [8, 10, 14, 16, 20, 24, 28, 32, 39, 48],
  radii: ["10px", "0px", "4px", "8px", "10px", "16px", "24px"],
  space: [0, 4, 8, 12, 16, 24, 32, 44],
  shadows: {
    s: "0 2px 4px rgba(0, 0, 0, 0.05)",
    m: "0 2px 4px rgba(0, 0, 0, 0.1)",
    l: "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)",
  },
};

let currentTheme: any;

const buildAliases = (color: string[]) => ({
  bgBase: color[0],
  bgSubtle: color[1],
  element: color[2],
  elementHover: color[3],
  elementActive: color[4],
  borderSubtle: color[5],
  border: color[6],
  borderHover: color[7],
  accent: color[8],
  accentHover: color[9],
  textLow: color[10],
  textHigh: color[11],
});

export const buildDefaultTheme = () => {
  const primaryColor = "pink";
  const secondaryColor = "yellow";
  const primaryLight = Object.values(allColors[primaryColor]);
  const secondaryLight = Object.values(allColors[secondaryColor]);

  const mainPrimaryColor = primaryLight;
  const mainSecondaryColor = secondaryLight;

  const mainPrimary = buildAliases(mainPrimaryColor);
  const mainSecondary = buildAliases(mainSecondaryColor);

  const danger = buildAliases(Object.values(allColors["red"]));
  const success = buildAliases(Object.values(allColors["green"]));
  const warning = buildAliases(Object.values(allColors["orange"]));
  const overlay = Object.values(allColors.blackA);
  const gray = Object.values(allColors.mauve);

  const theme = {
    ...defaultTheme,
    colors: {
      primary: mainPrimary,
      secondary: mainSecondary,
      danger,
      warning,
      gray,
      overlay,
      success,
    },
    fonts: {
      heading: "Inter",
      body: "Inter",
      button: "Inter",
    },
  };
  currentTheme = theme;
  return theme;
};

export const getCurrentTheme = () => currentTheme ?? buildDefaultTheme();

export const getValueByKey = (key: string) => {
  const keys = key.split(".");
  let value = getCurrentTheme();
  for (const key of keys) {
    value = value[key];
  }
  return value;
};
