import { routes } from "@/services/users.service";
import useSWR from "swr";
import { fetcherV2 } from "@/services/api";
import { Users } from "@/types/models/Users";

export function useMe(ready: boolean) {
  const { data, error, mutate } = useSWR(
    () => (ready ? [routes.me, {}] : undefined),
    fetcherV2<Users>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
      errorRetryInterval: 1000,
    }
  );

  return {
    user: data,
    refresh: mutate,
    isLoading: !error && !data,
    isError: error,
  };
}
