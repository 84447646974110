import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type Props = {
  children: React.ReactNode;
};

export interface Notification {
  id: string;
  mode: "info" | "warning" | "critical";
  dismissable: boolean;
  payload: ReactNode | string;
}

type NotificationsContextType = {
  notifications: Notification[];
  addNotification: (e: Notification) => void;
  removeNotification: (id: string) => void;
};

const NotificationContext = createContext<NotificationsContextType | undefined>(
  undefined
);

function NotificationsProvider({ children }: Props) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = useCallback((notification: Notification) => {
    console.log("Adding notifications " + notification.id);
    setNotifications((notifs) => {
      const existingNotification = notifs.find((n) => n.id === notification.id);
      if (!existingNotification) {
        notifs.push(notification);
      }
      return [...notifs];
    });
  }, []);

  const removeNotification = useCallback((id: string) => {
    setNotifications((ns) => [...ns.filter((n) => n.id !== id)]);
  }, []);

  const contextValues = useMemo(
    () => ({ notifications, addNotification, removeNotification }),
    [addNotification, notifications, removeNotification]
  );

  return (
    <NotificationContext.Provider value={contextValues}>
      {children}
    </NotificationContext.Provider>
  );
}

function useNotifications(): NotificationsContextType {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider"
    );
  }
  return context;
}

export { NotificationsProvider, useNotifications };
